

import axios from 'axios';

const API_URL = 'https://backend.azad.deloai.com'; // Replace with your backend URL

class Authentication {
  // Login method
  static async login(email, password) {
    try {
      const response = await axios.post(`${API_URL}/login`, { email, password });
      return response.data; // Expecting { success: true/false, message: string }
    } catch (error) {
      console.error('Error logging in:', error);
      return { success: false, message: 'An error occurred during login' };
    }
  }

  // Logout method
  static async logout() {
    try {
      const response = await axios.post(`${API_URL}/logout`);
      return response.data; // Expecting { success: true, message: string }
    } catch (error) {
      console.error('Error logging out:', error);
      return { success: false, message: 'An error occurred during logout' };
    }
  }
}

export default Authentication;
