import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './CourseDetail.css';
import { uploadFile } from '../../upload-vedio';

import { v4 as uuidv4 } from 'uuid';

const CourseDetail = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [chapterType, setChapterType] = useState('');
  const [chapterData, setChapterData] = useState({
    title: '',
    pdf_url: null,
    text: '',
    videoFile: null,
    thumbnail: null,
    duration: '',
    professor: '',
    notes: '',
    isPreview: false,
  });
  const [editMode, setEditMode] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isRearrangeMode, setIsRearrangeMode] = useState(false);
  const [chapterOrder, setChapterOrder] = useState([]);
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(false);
  const getFileNameFromUrl = (url) => {
    // Create a new URL object
    const urlObject = new URL(url);
    // Extract the file name from the pathname
    return urlObject.pathname.split('/').pop();
  };
  
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const { data } = await axios.get(`https://backend.azad.deloai.com/admin/course/courses/${courseId}`);
        setCourse(data);
        if (data.chapters.length > 0) {
          setSelectedChapter(data.chapters[0]);
          setChapterOrder(data.chapters.map(ch => ch.id));
        }
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchCourse();
  }, [courseId, reload]);

  const handleChapterSelect = (chapterId) => {
    const chapter = course?.chapters.find(ch => ch.id === chapterId);
    console.log('chapter selected')
    console.log(chapter.video.video_url.replace("https://azadicsacademy.s3.ap-southeast-2.amazonaws.com", newBaseUrl))
    setSelectedChapter(chapter);

    setEditMode(false);
  };

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => {
    setModalIsOpen(false);
    resetChapterData();
  };

  const resetChapterData = () => {
    setChapterType('');
    setChapterData({
      title: '',
      pdf_url: '',
      text: '',
      videoFile: null,
      thumbnail: null,
      duration: '',
      professor: '',
      notes: '',
      isPreview: false,
    });
    setUploadProgress(0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChapterData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleTypeChange = (e) => setChapterType(e.target.value);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${secs}s`;
  };

  const handleNavigateToCourse = () => {
    navigate('/courses');
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      const video = document.createElement('video');
      video.src = videoUrl;

      video.addEventListener('loadedmetadata', () => {
        const duration = video.duration;
        const formattedDuration = formatDuration(duration);
        setChapterData(prevData => ({ ...prevData, videoFile: file, duration: formattedDuration }));
        URL.revokeObjectURL(videoUrl);
      });
    }
  };

  const handlePDFChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setChapterData(prevData => ({ ...prevData, pdf_url: file }));
    }
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setChapterData(prevData => ({ ...prevData, thumbnail: file }));
    }
  };

  const handleSaveChapter = async () => {
    if (!chapterData.title.trim()) {
      alert('Title is required.');
      return;
  }
  
  if (chapterType === 'video' && !chapterData.videoFile) {
      alert('Video file is required for a video chapter.');
      return;
  }
  
  if (chapterType === 'pdf' && !chapterData.pdf_url) {
      alert('PDF file is required for a PDF chapter.');
      return;
  }
  
  if (chapterType === 'text' && !chapterData.text.trim()) {
      alert('Text content is required for a text chapter.');
      return;
  }
  setLoading(true); // Start loading

    setUploadProgress(0);
    const uniqueId = uuidv4();

    try {
      let newChapter = {};

      if (chapterType === 'video' && chapterData.videoFile) {
        const videoUrl = await uploadFile(chapterData.videoFile, setUploadProgress, `Courses/${courseId}/video-${uniqueId}`);
        let thumbnailUrl = '';

        if (chapterData.thumbnail) {
            // If a thumbnail image is selected, upload it
            thumbnailUrl = await uploadFile(chapterData.thumbnail, `Courses/${courseId}/images-${uniqueId}`);
        } else {
            // If no thumbnail image is selected, set it to an empty string
            thumbnailUrl = '';
        }
        
        // You can now use thumbnailUrl as needed
        

        newChapter = {
          type: chapterType,
          video: {
            video_url: videoUrl,
            thumbnail: thumbnailUrl,
            title: chapterData.title,
            duration: chapterData.duration,
            professor: chapterData.professor,
            notes: chapterData.notes,
            isPreview: chapterData.isPreview,
          },
        };
      } else if (chapterType === 'pdf') {
        const pdfUrl = await uploadFile(chapterData.pdf_url, setUploadProgress,`Courses/${courseId}/pdfs-${uniqueId}`);

        newChapter = {
          type: chapterType,
          pdf: {
            title: chapterData.title,
            pdf_url: pdfUrl,
            isPreview: chapterData.isPreview,
          },
        };
      } else if (chapterType === 'text') {
        newChapter = {
          type: chapterType,
          text: {
            title: chapterData.title,
            text: chapterData.text,
            isPreview: chapterData.isPreview,
          },
        };
      }

      await axios.post(`https://backend.azad.deloai.com/admin/course/${courseId}/chapters`, newChapter);

      const updatedCourse = await axios.get(`https://backend.azad.deloai.com/admin/course/courses/${courseId}`);
      setCourse(updatedCourse.data);
      setSelectedChapter(updatedCourse.data.chapters[0]);
      handleCloseModal();
      alert('Chapter added successfully!');
    } catch (error) {
      console.error('Error saving chapter:', error);
      setUploadProgress(0);
      alert('Failed to add chapter. Please try again.');
    } finally {
      setReload(!reload);
      handleCloseModal();
      setLoading(false); // End loading

    }
  };

  const handleRearrangeToggle = () => setIsRearrangeMode(!isRearrangeMode);

  const handleMoveChapter = (index) => {
    const newOrder = [...chapterOrder];
    if (index > 0) {
      const [movedChapter] = newOrder.splice(index, 1);
      newOrder.splice(index - 1, 0, movedChapter);
      setChapterOrder(newOrder);
    }
  }

  const handleSaveOrder = async () => {
    try {
      await axios.put(`https://backend.azad.deloai.com/admin/course/${courseId}/chapters`, { chapter_ids: chapterOrder });

      const updatedCourse = await axios.get(`https://backend.azad.deloai.com/admin/course/courses/${courseId}`);
      setCourse(updatedCourse.data);
      setSelectedChapter(updatedCourse.data.chapters[0]);
      setIsRearrangeMode(false);
    } catch (error) {
      console.error('Error saving chapter order:', error);
    } finally {
      setReload(!reload);
    }
  };

  const handleDeleteChapter = async (chapterId, chapterName) => {
    if (window.confirm(`Are you sure you want to delete this chapter ${chapterName}?`)) {
      try {
        await axios.delete(`https://backend.azad.deloai.com/admin/course/courses/${courseId}/chapters/${chapterId}`);
        const updatedCourse = await axios.get(`https://backend.azad.deloai.com/admin/course/courses/${courseId}`);
        setCourse(updatedCourse.data);
        setSelectedChapter(updatedCourse.data.chapters.length > 0 ? updatedCourse.data.chapters[0] : null);
      } catch (error) {
        console.error('Error deleting chapter:', error);
      } finally {
        setReload(!reload);
      }
    }
  };

// New base URL
const newBaseUrl = "https://d26nja80kquv0g.cloudfront.net";

// Replace the old base URL with the new one
// const newUrl = originalUrl.replace("https://azadicsacademy.s3.ap-southeast-2.amazonaws.com", newBaseUrl);

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    const [category, field] = name.split('.');
    setSelectedChapter(prevChapter => ({
      ...prevChapter,
      [category]: {
        ...prevChapter[category],
        [field]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleSaveEdit = async () => {
    try {
      const updatedChapter = { ...selectedChapter };
      let response;
      if (updatedChapter.type === 'pdf') {
        response = await axios.post(`https://backend.azad.deloai.com/admin/course/edit_chapter/${selectedChapter.id}`, {
          title: updatedChapter.pdf.title,
          isPreview: updatedChapter.pdf.isPreview,
        });
      } else if (updatedChapter.type === 'text') {
        response = await axios.post(`https://backend.azad.deloai.com/admin/course/edit_chapter/${selectedChapter.id}`, {
          title: updatedChapter.text.title,
          text: updatedChapter.text.text,
          isPreview: updatedChapter.text.isPreview,
        });
      } else if (updatedChapter.type === 'video') {
        response = await axios.post(`https://backend.azad.deloai.com/admin/course/edit_chapter/${selectedChapter.id}`, {
          title: updatedChapter.video.title,
          professor: updatedChapter.video.professor,
          notes: updatedChapter.video.notes,
          isPreview: updatedChapter.video.isPreview,
        });
      }
      const updatedCourse = await axios.get(`https://backend.azad.deloai.com/admin/course/courses/${courseId}`);
      setCourse(updatedCourse.data);
      setEditMode(false);
    } catch (error) {
      console.error('Error saving chapter edits:', error);
    }
  };

  const renderChapterContent = (chapter) => {
  
    if (!chapter) return <div>Unknown chapter type</div>;
    console.log("hkhsdkskhd")
    console.log(chapter.video.video_url)
    const videoFileName = chapter.video.video_url ? getFileNameFromUrl(chapter.video.video_url) : '';

    switch (chapter.type) {
      case 'pdf':
        return (
          <div className="chapter-content">
            <h1>Chapter</h1>
            <form>
              <label>Title: {editMode ? (
                <input
                  type="text"
                  name="pdf.title"
                  value={chapter.pdf.title}
                  onChange={handleEditChange}
                />
              ) : (
                <input
                  type="text"
                  name="pdf.title"
                  value={chapter.pdf.title}
                  readOnly
                />
              )}</label>
              <label>
                Preview:
                <input
                  type="checkbox"
                  name="pdf.isPreview"
                  checked={chapter.pdf.isPreview}
                  onChange={handleEditChange}
                  disabled={!editMode}
                />
              </label>
              <a href={chapter.pdf.pdf_url} target="_blank" rel="noopener noreferrer">Download PDF</a><br />
              <iframe
                src={chapter.pdf.pdf_url}
                width="600"
                height="400"
                title="PDF Viewer"
              ></iframe><br />
              {editMode && <button type="button" onClick={handleSaveEdit}>Save</button>}
              {!editMode && <button type='button' onClick={() => setEditMode(!editMode)}>Edit</button>}
            </form>
          </div>
        );
      case 'text':
        return (
          <div className="chapter-content">
            <h1>Chapter</h1>
            <form>
              <label>Title: {editMode ? (
                <input
                  type="text"
                  name="text.title"
                  value={chapter.text.title}
                  onChange={handleEditChange}
                />
              ) : (
                <input
                  type="text"
                  name="text.title"
                  value={chapter.text.title}
                  readOnly
                />
              )}</label>
              <label>Text: {editMode ? (
                <textarea
                  name="text.text"
                  value={chapter.text.text}
                  onChange={handleEditChange}
                />
              ) : (
                <textarea
                  name="text.text"
                  value={chapter.text.text}
                  readOnly
                />
              )}</label>
              <label>
                Preview:
                <input
                  type="checkbox"
                  name="text.isPreview"
                  checked={chapter.text.isPreview}
                  onChange={handleEditChange}
                  disabled={!editMode}
                />
              </label>
              {editMode && <button type="button" onClick={handleSaveEdit}>Save</button>}
              {!editMode && <button type='button' onClick={() => setEditMode(!editMode)}>Edit</button>}
            </form>
          </div>
        );
      case 'video':
        return (
          <div className="chapxer-content">
            <h1>Chapter : {chapter.video.title}</h1>
            <form>
              <label>Title: {editMode ? (
                <input
                  type="text"
                  name="video.title"
                  value={chapter.video.title}
                  onChange={handleEditChange}
                  required
                />
              ) : (
                <input
                  type="text"
                  name="video.title"
                  value={chapter.video.title}
                  readOnly
                  

                />
              )}</label>
              <label>Video:</label>
              <video            key={chapter.video.video_url} // Ensure re-render on URL change
 width="400px" height="200px" controls style={{ border: '1px solid black' }}>
              <source src={chapter.video.video_url.replace("https://azadicsacademy.s3.ap-southeast-2.amazonaws.com", newBaseUrl)} type="video/mp4" />
              Your browser does not support the video tag.
              </video>
              <div>
            <p>File Name: {videoFileName}</p>
          </div>
              {/* {chapter.video.thumbnail && (
                <div>
                  <label>Thumbnail:</label>
                  <img
                    src={chapter.video.thumbnail}
                    alt={chapter.video.title}
                    width="300px"
                    height="200px"
                  />
                </div>
              )} */}
             
              <label>Notes: {editMode ? (
                <textarea
                  name="video.notes"
                  value={chapter.video.notes}
                  onChange={handleEditChange}
                />
              ) : (
                <textarea
                  name="video.notes"
                  value={chapter.video.notes}
                  readOnly
                />
              )}</label>
              <label>
                Preview:
                <input
                  type="checkbox"
                  name="video.isPreview"
                  checked={chapter.video.isPreview}
                  onChange={handleEditChange}
                  disabled={!editMode}
                />
              </label>
              {editMode && <button type="button" onClick={handleSaveEdit}>Save</button>}
              {!editMode && <button type='button' onClick={() => setEditMode(!editMode)}>Edit</button>}
            </form>
          </div>
        );
      default:
        return <div>Unknown chapter type</div>;
    }
  };

  return (
    <div className="course-detail-container">
      <nav className="course-detail-nav">
        <h1>Azad Ics Academy</h1>
        <h3>Course Builder</h3>
        <h4>Chapters</h4>
        <ul>
          {course && chapterOrder.map((chapterId, index) => {
            const chapter = course.chapters.find(ch => ch.id === chapterId);
            if (!chapter) return null;

            return (
              <li key={chapter.id} className="chapter-item">
                <button
                  onClick={() => handleChapterSelect(chapter.id)}
                  className={selectedChapter?.id === chapter.id ? 'active' : ''}
                >
                  {chapter.type === 'pdf' ? chapter.pdf.title : chapter.type === 'text' ? chapter.text.title : chapter.video.title}
                </button>
                {!isRearrangeMode && (
                  <button
                    onClick={() => handleDeleteChapter(chapter.id, chapter.type === 'pdf' ? chapter.pdf.title : chapter.type === 'text' ? chapter.text.title : chapter.video.title)}
                    className="delete-chapter-button"
                  >
                    -
                  </button>
                )}
                {isRearrangeMode && index > 0 && (
                  <button
                    onClick={() => handleMoveChapter(index)}
                    className="rearrange-arrow"
                  >
                    ▲
                  </button>
                )}
              </li>
            );
          })}
          <li>
            {!isRearrangeMode && (
              <>
                <button onClick={handleOpenModal} className="add-chapter-button">Add Chapter</button>
                <button onClick={handleRearrangeToggle} className="rearrange-button">
                  {isRearrangeMode ? 'Done Rearranging' : 'Rearrange'}
                </button>
              </>
            )}
            {isRearrangeMode && (
              <button onClick={handleSaveOrder} className="save-order-button">Save Order</button>
            )}
          </li>
          <li>
            <button onClick={handleNavigateToCourse} className="navigate-course-button">Go Back to Courses</button>
          </li>
        </ul>
      </nav>
      <main className="course-detail-main">
        {selectedChapter ? renderChapterContent(selectedChapter) : <p>Select a chapter to view its content.</p>}
      </main>

      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} ariaHideApp={false}>
        <h2>Add Chapter</h2>
        <label>
          Chapter Type:
          <select value={chapterType} onChange={handleTypeChange}>
            <option value="">Select Type</option>
            <option value="pdf">PDF</option>
            <option value="text">Text</option>
            <option value="video">Video</option>
          </select>
        </label>

        {chapterType === 'pdf' && (
          <div>
            <label>
              Title:
              <input type="text" name="title" value={chapterData.title} onChange={handleChange}   disabled={loading} // Disable input when loading
              />
            </label>
            <label>
              PDF file:
              <input type="file" name="pdfFile" accept="application/pdf" onChange={handlePDFChange}   disabled={loading} // Disable input when loading
              />
            </label>
            <label>
              Preview:
              <input
                type="checkbox"
                name="isPreview"
                checked={chapterData.isPreview}
                onChange={(e) => setChapterData(prevData => ({ ...prevData, isPreview: e.target.checked }))}
                disabled={loading} // Disable input when loading
                />
            </label>
            {uploadProgress > 0 && (
              <div className="upload-progress">
                <p>Upload Progress: {uploadProgress}%</p>
              </div>
            )}
          </div>
        )}

        {chapterType === 'text' && (
          <div>
            <label>
              Title:
              <input type="text" name="title" value={chapterData.title} onChange={handleChange}   disabled={loading} // Disable input when loading
              />
            </label>
            <label>
              Text:
              <textarea name="text" value={chapterData.text} onChange={handleChange}  disabled={loading} // Disable input when loading
 />
            </label>
            <label>
              Preview:
              <input
                type="checkbox"
                name="isPreview"
                checked={chapterData.isPreview}
                onChange={(e) => setChapterData(prevData => ({ ...prevData, isPreview: e.target.checked }))}
              />
            </label>
          </div>
        )}

        {chapterType === 'video' && (
          <div>
            <label>
              Title:
              <input type="text" name="title" value={chapterData.title} onChange={handleChange}
                disabled={loading} // Disable input when loading
                required
                />
            </label>
            <label>
              Video File:
              <input type="file" name="videoFile" accept="video/mp4" onChange={handleFileChange} 
                disabled={loading} // Disable input when loading
                required
                
/>
            </label>
            <label>
              Thumbnail Image:
              <input type="file" accept="image/*" onChange={handleThumbnailChange}
                disabled={loading} // Disable input when loading
                />
            </label>
            <label>
              Duration:
              <input type="text" name="duration" value={chapterData.duration} readOnly />
            </label>
            <label>
              <input type="text" hidden name="professor" value={chapterData.professor} onChange={handleChange} 
                disabled={loading} // Disable input when loading
/>
            </label>
            <label>
              Notes:
              <textarea name="notes" value={chapterData.notes} onChange={handleChange} />
            </label>
            <label>
              Preview:
              <input
                type="checkbox"
                name="isPreview"
                checked={chapterData.isPreview}
                onChange={(e) => setChapterData(prevData => ({ ...prevData, isPreview: e.target.checked }))}
                disabled={loading} // Disable input when loading

              />
            </label>
            {uploadProgress > 0 && (
              <div className="upload-progress">
                <p>Upload Progress: {uploadProgress}%</p>
              </div>
            )}
          </div>
        )}
<button 
  onClick={handleSaveChapter} 
  disabled={loading} // Disable button when loading
>
  Save Chapter
</button>


        <button onClick={handleCloseModal}
          disabled={loading} // Disable button when loading

>Close</button>
      </Modal>
    </div>
  );
};

export default CourseDetail;
