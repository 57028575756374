import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import Authentication from '../../Models/Authentication/Authentication';
import './Login.css';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await Authentication.login(email, password);
    if (result.success) {
      login();
      navigate('/');
    } else {
      setMessage(result.message);
    }
  };

  return (
    <div className="container321">
      <div className="form-container321">
        <h2>Login Form</h2>
        <form onSubmit={handleSubmit} className="form321">
          <div className="input-field321">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="password-field321 input-field321">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className="toggle-password321"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? '🙈' : '👁️'}
            </span>
          </div>
          <button type="submit">Login</button>
        </form>
        {message && <p className="form-message321">{message}</p>}
      </div>
    </div>
  );
}

export default LoginForm;
