import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import Login from './screens/Authentication/Login';
import Logout from './screens/Authentication/Logout';
import StudentScreen from './screens/Students/StudentScreen';
import CourseScreen from './screens/Course/CourseScreen';
import CourseDetail from './screens/Course/CourseDetail';
import EditCourseScreen from './screens/Course/EditCourse';
import StudentDetail from './screens/Students/StudentDetail';
import PackageScreen from './screens/Packages/PackageScreen';
import EditPackageScreen from './screens/Packages/EditPackage';
import PackageBuilderScreen from './screens/Packages/PackageBuilder';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<ProtectedRoute element={StudentScreen} />} />
            <Route path="/courses" element={<ProtectedRoute element={CourseScreen} />} />
            <Route path="/courses/:courseId" element={<ProtectedRoute element={CourseDetail} />} />
            <Route path="/course/edit/:course_id" element={<ProtectedRoute element={EditCourseScreen} />} />
            <Route path="/students/:studentId" element={<ProtectedRoute element={StudentDetail} />} />
            <Route path="/packages" element={<ProtectedRoute element={PackageScreen} />} />
            <Route path="/package/edit/:package_id" element={<ProtectedRoute element={EditPackageScreen} />} />
            <Route path="/package/build/:package_id" element={<ProtectedRoute element={PackageBuilderScreen} />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
